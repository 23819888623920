import * as React from "react"
import Layout from "../components/layout"
import Temoignages from "../components/Artiste"

const ArtistePage = () => (
  <Layout>
    <Temoignages />
  </Layout>
)

export default ArtistePage