import React from 'react'
import styled from 'styled-components'
import { Link } from "gatsby"
import { FaLinkedin } from 'react-icons/fa';

const Artiste = () => {
  return (
    <Portfolio>
    <BioImage>
    <LK>
    <Link href="https://atelierderg.fr/" rel="noreferrer noopener" target="_blank"> <HeroItemsH2>  Mon site de Décoration </HeroItemsH2> </Link>
    </LK>
    </BioImage>
    </Portfolio>
  )
}

export default Artiste

const LK = styled.div`
display: flex;
flex-direction: column;
text-align: left;
a{
  font-size: 60px;
  margin-bottom: 2rem;
  &:hover {
    color: #86b1ee;
    transition: 0.3s ease-out;  
    cursor: pointer;
}
    @media screen and (max-width: 768px) {
      a{
        font-size: 50px;
      }
}
`

const Portfolio = styled.div`

      @media screen and (max-width: 768px) {

      }
`
const HeroItemsH2 = styled.div` 
    font-size: 6rem; 
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-weight: bold;
    padding: 2rem 5rem;
    text-align: center;
    color: yellow;
      @media screen and (max-width: 768px) {
          padding: 0rem 0.5rem;
          font-size: 3rem; 
          margin-top: 2.5rem;
        }
`

const BioImage = styled.div`
  flex: 50%;
  text-align: center;
  heigth: auto;
  display: block;
  margin-top: 5rem ;
      @media screen and (max-width: 768px) {
        margin-top: 0rem ;
      }
`